<!--导出弹窗组件-WXW-->
<template>
  <div id="exportUp" v-show="exportUp">
    <div class="exportUp_y" @click.stop="cancelClick"></div>
    <div class="exportUp_box" @click.stop>
      <div class="exportUp_head clear">
        <span>请选择导出的配置字段</span>

        <el-button class="button cancelButton" @click="cancelClick" style="float: right;margin-left: 20px;">取消
        </el-button>
        <el-button class="button exportButton" @click="exportClick" style="float: right;">确定并导出</el-button>
                <el-select v-model="nowExport" placeholder="请选择" size="small" style="float: right;">
          <el-option label="导出选中全部数据"  value="1"></el-option>
          <el-option label="导出当前页全部数据" value="2"></el-option>
        </el-select>
      </div>
      <div class="exportUp_table">
        <el-table v-if="isTable" :data="tableData" ref="multipleTable" show-overflow-tooltip highlight-current-row
                  size="small" border
                  @selection-change="handleSelectionChange" :header-row-style="headStyle" :height="tableH"
                  style="width: 100%">
          <el-table-column type="selection" width="50" header-align="center" align="center"></el-table-column>
          <el-table-column type="index" label="序号" width="50" header-align="center" align="center"></el-table-column>
          <el-table-column label="字段名称" prop="label" header-align="center" align="left"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<!--
tableData:导出的配置字段数据
isExportUp：开关
-->
<script>
  import configBasics from '../../utils/configBasics'

  export default {
    name: "ExportUp",
    props: ['tableData', 'isExportUp'],
    data() {
      return {
        exportUp: false,
        isTable: true,
        tableH: '200',
        // 导出选项
        nowExport: '1',
        headStyle: {
          'color': '#303133'
        },
        exportListKey: [],
        exportListVal: [],
        autoList: []
      }
    },
    methods: {
      /*样式*/
      cssFun() {
        let that = this;
        that.isTable = false;

        that.$nextTick(function () {
          let boxH = $("#exportUp .exportUp_box").height();
          let headH = $("#exportUp .exportUp_box .exportUp_head").outerHeight();
          $("#exportUp .exportUp_box .exportUp_table").outerHeight(boxH - headH);
          that.tableH = boxH - headH;
          that.isTable = true;
          setTimeout(function () {
            configBasics.niceScrollFun($(".exportUp_table .el-table>div").eq(2), true);
            $(".exportUp_table .el-table>div").eq(2).getNiceScroll().resize();
            that.autoTable();
          }, 100);
        });
      },
      /*选中的数据*/
      handleSelectionChange(data) {
        let that = this;
        that.exportListKey = [];
        that.exportListVal = [];
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            that.exportListKey.push(data[i].prop);
            that.exportListVal.push(data[i].label);
          }
        }
      },
      /*取消事件*/
      cancelClick() {
        let that = this;
        that.exportUp = false;
        that.$emit('cancelClick');
      },
      /*确定事件*/
      exportClick() {
        let that = this;
        if (that.exportListKey.length <= 0 || that.exportListVal <= 0) {
          that.$message({
            message: '请选择字段名称！',
            type: 'warning',
            duration: 1000
          });
        } else {
          let data = {
            nowExport: that.nowExport,
            exportListKey: that.exportListKey,
            exportListVal: that.exportListVal
          };
          that.$emit('cancelClick', data);
        }
      },
      /*默认选中*/
      autoTable() {
        let that = this;
        that.autoList = [];
        if (that.tableData && that.tableData.length > 0 && that.exportUp) {
          for (let i = 0; i < that.tableData.length; i++) {
            if (that.tableData[i].showhide) {
              that.autoList.push(that.tableData[i]);
            }
          }
          that.$nextTick(function () {
            for (let k = 0; k < that.autoList.length; k++) {
              that.$refs.multipleTable.toggleRowSelection(that.autoList[k]);
            }
          })
        }
      }
    },
    mounted() {
      let that = this;
      that.exportUp = that.isExportUp;
      if (that.exportUp) {
        that.cssFun();
      }
      $(window).resize(function () {
        if (that.exportUp) {
          that.cssFun();
        }
      });
    },
    watch: {
      isExportUp: {
        handler(curVal, oldVal) {
          let that = this;
          that.exportUp = that.isExportUp;
          if (that.exportUp) {
            that.cssFun();
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  #exportUp {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  #exportUp .exportUp_y {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  #exportUp .exportUp_box {
    width: 40%;
    height: 50%;
    background: #fff;
    padding: 12px 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .exportUp_box .exportUp_head {
    padding-bottom: 12px;
    line-height: 28px;
  }
</style>
