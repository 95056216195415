<!--查询组件-WXW-->
<template>
  <div id="tableQuery" :class="{'border':isQuery,className}">
    <div class="tableQuery_nav">
      <div class="tableQueryForm" v-if="isQuery" @click.stop>
        <div class="tableQueryForm_box">
          <div>
            <div class="query_list" v-if="formListData.length > 0">
              <div class="list_label" v-if="moveBtnP" :style="{width:moveLabelWidth}">填写条件
              </div>
              <div class="list_form">
                <el-form :model="formInline" class="demo-ruleForm" :label-width="property.labelWidth" size="small"
                         ref="ModuleQuery">
                  <superForm :formList="formListData" :formInline="formInline" :customArrKey="customArrKey" @selectChange="selectChange" @radioChange="radioChange"
                         ref="childList">
                  </superForm>
                </el-form>
              </div>
            </div>
            <template v-for="(item,index) in formMoveData">
              <div class="query_list" :id="item.name">
                <div class="list_label" :style="{width:moveLabelWidth}">{{item.label}}</div>
                <div class="list_form" style="padding-bottom: 6px;">
                  <template v-for="(list,ind) in item.data">
                    <div class="list_form_list" :id="item.name+'-'+list.value" :value="list.value"
                         @click="listClickFun(item,index,list,ind)">
                      {{list.label}}
                      <span class="list_active el-icon-check"></span>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="move_btn_box" v-if="moveBtnP">
          <template v-for="item in btnList">
            <template v-if="item.name==='exportButton'"> <!--导出-->
              <el-button v-if="noright" class="button" :class="item.type" @click="exportFun">
                {{item.label}}
              </el-button>
              <el-button v-else class="button" :class="item.type" title="右击可配置导出字段" @click="exportFun"
                         @contextmenu.prevent.native="exportRightFun">
                {{item.label}}
              </el-button>
            </template>
            <template v-else-if="item.name==='addButton'">
              <el-button class="button" :class="item.type" @click="buttonFun(item)">{{item.label}}
              </el-button>
            </template>
            <template v-else>
              <el-button class="button" :class="item.type" @click="buttonFun(item)">
                {{item.label}}
              </el-button>
            </template>
          </template>
          <el-button class="move_btn" type="text" @click="moveUpFun">收起
            <i class="el-icon-arrow-up"></i>
          </el-button>
        </div>
      </div>
      <!--无查询时显示的-->
      <div id="noData" v-else>
        <span class="iconfont iconbianji"></span>
        <span>{{property.tableName}}</span>
      </div>
    </div>
    <!--按钮-->
    <div id="tableQueryBtn" v-if="!moveBtnP" @click.stop>
      <div class="tableQueryBtn_nav">
        <template v-for="item in btnList">
          <template v-if="item.name==='exportButton'"> <!--导出-->
            <el-button v-if="noright" class="button" :class="item.type" @click="exportFun">
              {{item.label}}
            </el-button>
            <el-button v-else class="button" :class="item.type" title="右击可配置导出字段" @click="exportFun"
                       @contextmenu.prevent.native="exportRightFun">
              {{item.label}}
            </el-button>
          </template>
          <template v-else-if="item.name==='addButton'"> <!--添加-->
            <el-button class="button" :class="item.type" @click="buttonFun(item)">{{item.label}}
            </el-button>
          </template>
          <template v-else>
            <el-button class="button" :class="item.type" @click="buttonFun(item)">{{item.label}}
            </el-button>
          </template>
        </template>
        <el-button v-if="moveP" class="move_btn" type="text" @click="moveDownFun">更多
          <i class="el-icon-arrow-down"></i>
        </el-button>
      </div>
    </div>
    <!--导出弹窗-->
    <ExportUp :tableData="exportUpData" :isExportUp="isExportUp" @cancelClick="cancelClick"></ExportUp>
  </div>
</template>
<!--
传递的数据
formList:arr,表单数据（必传）
formInline:json,表单对应的key（必传）
customArrKey:arr,自定义控件-双控件对应的key（必传）
btnList:arr,操作按钮
exportUpData:arr导出的配置字段数据
property:{
  labelWidth:"label宽度"
  typeName:"当前页面的name"（必传）
  moveLabelWidth:更多里面label宽度
  tableName:"无查询控件时显示的文本信息（用户列表）",
  isQuery:"是否有查询条件"(true)
  noright:"是否有右击导出字段弹窗"
}

返回的方法
moveQueryFun:(formInline)回车查询的方法
btnClickFun:(item,formInline)点击按钮返回的方法
cancelFun:（导出字段数据）导出
formDataFun:(formInline)标签点击事件传递的数据
-->
<script>
  import superForm from './SuperForm'
  import bus from '../../utils/bus'
  import ExportUp from './ExportUp'
  import configBasics from '../../utils/configBasics'

  export default {
    name: "TableQuery",
    props: ["formList", "formInline", "customArrKey", "btnList", "exportUpData", "property", "className"],
    components: {superForm, ExportUp},
    data() {
      return {
        noright: false,
        /*是否显示更多按钮*/
        moveP: false,
        /*导出开关*/
        isExportUp: false,
        /*form表单label宽度*/
        labelWidth: "100px",
        /*更多里面label宽度*/
        moveLabelWidth: "100px",
        /*更多里面的按钮是否显示*/
        moveBtnP: false,
        /*表单控件数据*/
        formListData: [],
        /*多选数据*/
        formMoveData: [],
        /*是否有查询控件*/
        isQuery: true
      }
    },
    created() {
      let that = this;

      if (that.property && that.property.labelWidth) {
        that.labelWidth = that.property.labelWidth;
      }
      if (that.property && that.property.moveLabelWidth) {
        that.moveLabelWidth = that.property.moveLabelWidth;
      }
      if (that.property && that.property.noright) {
        that.noright = true;
      }
      if (that.property) {
        if (that.property.isQuery === false) {
          that.isQuery = false;
        }
      }
    },
    methods: {
      selectDepart(val){
        // console.log(val)
        this.$emit('selectDepart',val);
        this.BUS.$emit('selectDepart',val)
      },
      selectChange(...item) {
        let that = this;
        /*返回事件*/
        that.$emit('selectChange',...item);
      },
      radioChange(...item) {
        let that = this;
        /*返回事件*/
        that.$emit("radioChange", ...item);
      },
      /*展开更多*/
      moveDownFun() {
        // console.log("展开");
        this.queryMaxH();
        let w = $("#tableQuery").width();
        $("#tableQuery .tableQueryForm").outerWidth(w + 2);
        $("#tableQuery .tableQueryForm").css({
          "height": "auto",
          "border": '1px solid #dddddd',
          "left": "-1px",
          "top": "-1px"
        });
        $("#tableQuery .tableQueryForm .tableQueryForm_box .query_list").css({
          height: "auto"
        });
        this.moveBtnP = true;
        configBasics.niceScrollFun($("#tableQuery .tableQueryForm .tableQueryForm_box"), false);
        $("#tableQuery .tableQueryForm .tableQueryForm_box").getNiceScroll().resize();
      },
      /*收起更多*/
      moveUpFun() {
        if (this.moveBtnP) {
          // console.log("收起");
          $("#tableQuery .tableQueryForm").css({
            "width": "100%",
            "height": "100%",
            "border": 'none',
            "left": "0",
            "top": "0"
          });
          $("#tableQuery .tableQueryForm .tableQueryForm_box .query_list").css({
            height: "59px"
          });
          this.moveBtnP = false;
          $("#tableQuery .tableQueryForm .tableQueryForm_box").getNiceScroll().remove();
        }
      },
      /*导出右击事件*/
      exportRightFun() {
        // console.log("导出右击事件");
        let that = this;
        that.isExportUp = true;
      },
      /*标签的点击事件*/
      listClickFun(item, index, list, ind) {
        // console.log(item, index, list, ind, "标签的点击事件");
        if (item.isSingle) {
          /*单选*/
          if ($("#" + item.name).find(".list_form_list").eq(ind).attr("class").split(" ").indexOf("active") !== -1) {
            $("#" + item.name).find(".list_form_list").removeClass("active");
          } else {
            $("#" + item.name).find(".list_form_list").removeClass("active");
            $("#" + item.name).find(".list_form_list").eq(ind).addClass("active");
          }
        } else {
          /*多选*/
          if ($("#" + item.name).find(".list_form_list").eq(ind).attr("class").split(" ").indexOf("active") !== -1) {
            $("#" + item.name).find(".list_form_list").eq(ind).removeClass("active");
          } else {
            $("#" + item.name).find(".list_form_list").eq(ind).addClass("active");
          }
        }
        this.$emit("formDataFun", this.formDataFun());
      },
      /*拼接form数据*/
      formDataFun() {
        // console.log("拼接form数据");
        let that = this;
        /*输入的表单数据*/
        let data1 = that.formInline;
        /*选择的表单数据*/
        let data = {};
        for (let i = 0; i < that.formMoveData.length; i++) {
          let id = that.formMoveData[i].name;
          let dom = $("#" + id).find(".list_form_list");
          let domVal = $("#" + id).find(".list_form_list.active");
          if (that.formMoveData[i].isSingle) {
            /*单选*/
            if (domVal.attr("value")) {
              data[id] = domVal.attr("value");
            } else {
              data[id] = "";
            }
          } else {
            /*多选*/
            data[id] = [];
            for (let i = 0; i < dom.length; i++) {
              if (dom.eq(i).attr("class").split(" ").indexOf("active") !== -1) {
                data[id].push(dom.eq(i).attr("value"));
              }
            }
          }
        }
        /*拼接*/
        let formData = {};
        for (let item in data1) {
          formData[item] = data1[item];
        }
        for (let item in data) {
          formData[item] = data[item];
        }
        return formData;
      },
      /*判断更多是否显示*/
      moveSHFun(val) {
        let that = this;
        that.$nextTick(function () {
          if (that.moveBtnP) {
            let w = $("#tableQuery").width();
            $("#tableQuery .tableQueryForm").outerWidth(w + 3);
          } else {
            $("#tableQuery .tableQueryForm").css({
              width: "100%"
            })
          }
          setTimeout(function () {
            that.moveP = false;
            if (that.isQuery) {
              let dom = $("#tableQuery .tableQueryForm .list_form").eq(0);
              if(val){
                dom = $(".className .tableQueryForm .list_form").eq(0);
              }
              let firstBoxW = dom.width();
              let listDom;
              if (that.formListData.length > 0) {
                listDom = dom.find(".form_list");
              } else {
                listDom = dom.find(".list_form_list");
              }
              let listW = 0;
              for (let i = 0; i < listDom.length; i++) {
                if (that.formListData.length > 0) {
                  listW += listDom.eq(i).outerWidth();
                } else {
                  listW += listDom.eq(i).outerWidth() + 12;
                }
              }
              if (that.formListData.length > 0) {
                if (that.formMoveData.length > 0 || listW > firstBoxW) {
                  that.moveP = true;
                }
              } else {
                if (that.formMoveData.length > 1 || listW > firstBoxW) {
                  that.moveP = true;
                }
              }
            }
            $("#tableQuery .tableQueryForm .tableQueryForm_box").getNiceScroll().resize();
          }, 200);
        });
      },
      /*整理数据*/
      dataFun() {
        // console.log("整理数据");
        let that = this;
        that.formListData = [];
        that.formMoveData = [];
        if (that.formList) {
          for (let i = 0; i < that.formList.length; i++) {
            if (that.formList[i].type === "select" && that.formList[i].showhide) {
              let data = [];
              for (let k = 0; k < that.formList[i].option.length; k++) {
                data.push({
                  label: that.formList[i].option[k].labelname,
                  value: that.formList[i].option[k].value
                });
              }
              that.formMoveData.push(
                {
                  label: that.formList[i].label,
                  name: that.formList[i].name,
                  isSingle: that.formList[i].multiple ? false : true,
                  data: data
                }
              );
            } else if (that.formList[i].type === "radio" && that.formList[i].showhide) {
              let data = [];
              for (let k = 0; k < that.formList[i].radiochildren.length; k++) {
                data.push({
                  label: that.formList[i].radiochildren[k].labelname,
                  value: that.formList[i].radiochildren[k].value
                });
              }
              that.formMoveData.push(
                {
                  label: that.formList[i].label,
                  name: that.formList[i].name,
                  isSingle: true,
                  data: data
                }
              );
            } else if (that.formList[i].type === "checkbox" && that.formList[i].showhide) {
              let data = [];
              for (let k = 0; k < that.formList[i].checkboxchildren.length; k++) {
                data.push({
                  label: that.formList[i].checkboxchildren[k].labelname,
                  value: that.formList[i].checkboxchildren[k].value
                });
              }
              that.formMoveData.push(
                {
                  label: that.formList[i].label,
                  name: that.formList[i].name,
                  isSingle: false,
                  data: data
                }
              );
            } else {
              that.formListData.push(that.formList[i]);
            }
          }
        } else {

        }
        /*判断更多是否显示*/
        that.moveSHFun(this.className);
        /*默认选中*/
        that.listActiveFun();
        // console.log(that.formListData, that.formMoveData);
      },
      /*默认选中*/
      listActiveFun() {
        let that = this;
        that.$nextTick(function () {
          if (that.formMoveData.length > 0 && that.formInline) {
            for (let item in that.formInline) {
              for (let i = 0; i < that.formMoveData.length; i++) {
                if (item === that.formMoveData[i].name) {
                  let id = "#" + item;
                  $(id).find(".list_form_list").removeClass("active");
                  if (that.formInline[item]) {
                    if (typeof that.formInline[item] === 'string') {
                      let id = "#" + item + '-' + that.formInline[item];
                      $(id).addClass("active");
                    } else {
                      for (let i = 0; i < that.formInline[item].length; i++) {
                        let id = "#" + item + '-' + that.formInline[item][i];
                        $(id).addClass("active");
                      }
                    }
                  }
                }
              }
            }
          }
        });
      },
      /*****************传递的点击事件*********************/
      /*回车查询*/
      tableQueryFun() {
        let that = this;
        // console.log(that.formDataFun(), "回车查询");
        that.$emit("moveQueryFun", that.formDataFun());
        that.moveUpFun();
      },
      /*操作按钮*/
      buttonFun(item) {
        let that = this;
        // console.log(that.formDataFun(), "操作按钮-查询数据");
        that.$emit("btnClickFun", item, that.formDataFun());
        that.moveUpFun();
      },
      /*导出点击事件*/
      exportFun() {
        // console.log("导出");
        let that = this;
        that.$emit("cancelFun");
      },
      /*配置字段里里面的导出*/
      cancelClick(params) {
        let that = this;
        that.isExportUp = false;
        if (params) {
          // console.log(params, "配置字段里里面的导出");
          that.$emit("cancelFun", params);
        }
      },
      /*查询条件的最大高度*/
      queryMaxH() {
        let winH = $(window).height();
        $("#tableQuery .tableQueryForm .tableQueryForm_box").css({
          maxHeight: winH * 0.5 + "px"
        });
        $("#tableQuery .tableQueryForm .tableQueryForm_box").getNiceScroll().resize();
      }
    },
    mounted() {
      let that = this;
      that.queryMaxH();
      /*整理数据*/
      that.dataFun();
      /*监听折叠事件*/
      bus.$on('menuZDCFun', function (params) {
        /*判断更多是否显示*/
        that.moveSHFun(this.className);
      });
      /*窗口点击*/
      // $(window).click(function () {
      //   that.moveUpFun();
      // });
      /*窗口改变事件*/
      $(window).resize(function () {
        that.$nextTick(function () {
          that.queryMaxH();
          /*判断更多是否显示*/
          that.moveSHFun(this.className);
        });
      });
      /*回车事件-查询组件的失去焦点*/
      $(document).keyup(function (event) {
        if (that.property && that.property.typeName) {
          if (that.property.typeName === that.$route.name) {
            if (event.keyCode == 13) {
              that.tableQueryFun();
            }
          }
        }
      });
      /*监听折叠事件*/
      that.BUS.$on('menuZDCFun', function () {
        setTimeout(function () {
          that.queryMaxH();
          /*判断更多是否显示*/
          that.moveSHFun(this.className);
        }, 360);
      });
    },
    watch: {
      formList: {
        handler() {
          let that = this;
          /*整理数据*/
          that.dataFun();
          if (that.property) {
            if (that.property.isQuery === false) {
              that.isQuery = false;
            }
          }
        },
        deep: true
      },
      formInline: {
        handler() {
          let that = this;
          /*默认选中*/
          that.listActiveFun();
          if (that.property) {
            if (that.property.isQuery === false) {
              that.isQuery = false;
            }
          }
          bus.$emit("formInline", that.formInline);
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  /*主体*/
  #tableQuery {
    width: 100%;
    height: 46px;
    background: #ffffff;
    display: flex;
    border: none;
    border-bottom: 1px solid #dddddd;
  }

  #tableQuery.border {
    border: 1px solid #dddddd;
    height: 60px;
  }

  /*控件*/
  #tableQuery .tableQuery_nav {
    flex: 1;
    position: relative;
  }

  #tableQuery .tableQueryForm {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #ffffff;
    transition: height 0.3s;
  }

  .tableQueryForm .tableQueryForm_box {
    width: 100%;
    overflow: hidden;
  }

  .tableQueryForm .tableQueryForm_box .query_list {
    height: 59px;
    min-height: 59px;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    display: flex;
  }

  .query_list .list_label {
    background: #f5f8fd;
    border-right: 1px solid #dddddd;
    padding: 0 6px;
    line-height: 58px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: #7cb2de;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .query_list .list_form {
    flex: 1;
    padding: 12px 0 0 0;
    overflow: hidden;
  }

  .list_form .list_form_list {
    display: inline-block;
    margin: 0 6px 6px 6px;
    border: 1px solid #DCDFE6;
    border-radius: 3px;
    padding: 5px 12px;
    position: relative;
    top: 2px;
    cursor: pointer;
    color: #606266;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .list_form_list .list_active {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-width: 0 0 18px 20px;
    border-style: solid;
    /* border-color: transparent transparent #0e90fe transparent; */
    font-size: 10px;
    color: #ffffff;
  }

  .list_form_list .list_active.el-icon-check:before {
    position: relative;
    left: -11px;
    top: 7px;
  }

  .list_form .list_form_list.active .list_active {
    display: block;
  }

  /* .list_form .list_form_list.active {
    border-color: #0e90fe;
    color: #0e90fe;
  }

  .list_form .list_form_list:hover {
    border-color: #0e90fe;
    color: #0e90fe;
  } */

  /*更多里面的按钮*/
  .tableQueryForm .move_btn_box {
    text-align: right;
    height: 60px;
    line-height: 60px;
    padding: 0 12px;
    border-top: 1px solid #dddddd;
    position: relative;
    top: -1px;
  }

  .tableQueryForm .move_btn_box .button {
    margin-left: 12px !important;
  }

  .move_btn {
    padding: 8px 0 !important;
  }

  /*按钮*/
  #tableQueryBtn {
    height: 100%;
    overflow: hidden;
    padding: 7px 0;
  }

  #tableQuery.border #tableQueryBtn {
    padding: 13px 0;
  }

  #tableQueryBtn .tableQueryBtn_nav {
    height: 32px;
    padding: 0 0;
    border-left: none;
  }

  #tableQuery.border #tableQueryBtn .tableQueryBtn_nav {
    padding-right: 12px;
  }

  #tableQuery.border .tableQueryBtn_nav {
    border-left: 1px solid #dddddd;
  }

  #tableQueryBtn .button {
    vertical-align: middle;
    position: relative;
    top: 0;
    margin-left: 12px !important;
  }

  /*无数据*/
  #noData {
    line-height: 46px;
    padding: 0 0;
    font-size: 16px;
  }

  #noData .iconfont {
    color: #00aaff;
  }
</style>
